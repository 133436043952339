import React from 'react';
import dayjs from 'dayjs';
import { FlagsContext } from '../contexts/FlagContext';
import { isTrustedWebActivity } from 'libs/twaChecker';
import { isAndroid, isUsingBrowser } from 'utils/userAgent';
import parsePhoneNumber from 'libphonenumber-js';
import config from '../config';
import { removeUtmCampaign, removeUtmRoute } from 'store/utm';
import {
  belajar_budidaya,
  DEEP_LINK_CREATE_FARM,
  kalkulator_budidaya,
  konsultasi_budidaya,
  rencana_panen,
  toko_budidaya
} from 'config/features';
import { trackerLandingPage } from 'libs/analytics';

const FORM_COMPLAINT = process.env.REACT_APP_FORM_COMPLAINT;

export const isDownloadPromptVisible =
  isAndroid() && isUsingBrowser() && isTrustedWebActivity() === false;

export const preventGoBack = () => {
  history.pushState(null, null, document.URL);
  window.addEventListener('popstate', function () {
    history.pushState(null, null, document.URL);
  });
};

export const goToRegister = (history) => {
  history.push('/login/phone');
};

export const getQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(searchParams.entries());
  return Object.keys(params).length === 0 && params.constructor === Object
    ? null
    : params;
};

export const redirectToWhatsapp = (message, number) => {
  const customNumber = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
    number
  )}`;
  return `${number ? customNumber : config.whatsapp.url}&text=${message}`;
};

export const handleComplaint = (leads_name, leads_id, token) => {
  const backUrl = encodeURIComponent(window.location.href);
  const source = 'Toko Budidaya';
  const urlFormComplaint = `${FORM_COMPLAINT}?back_url=${backUrl}&customer_name=${leads_name}&leads_id=${leads_id}&source=${source}${
    token ? `&token=${token}` : ''
  }`;
  window.location.href = urlFormComplaint;
};

export const setLandingPagePricingThemeBasedOnPlan = (plan) => {
  switch (plan) {
    case 'Panen Maksimal': {
      return {
        icon: 'white',
        bg: 'bg-aqua-600',
        color: 'color-brand-primary-white',
        recommended: false,
        chip: {
          bg: 'bg-observatory-100',
          color: 'color-observatory-500'
        },
        btn: {
          bg: 'bg-observatory-500',
          color: 'color-brand-primary-white'
        }
      };
    }
    case 'Untung Berlipat': {
      return {
        icon: 'white',
        bg: 'bg-observatory-500',
        color: 'color-brand-primary-white',
        recommended: false,
        chip: {
          bg: 'bg-observatory-100',
          color: 'color-observatory-500'
        },
        btn: {
          bg: 'bg-brand-primary-white',
          color: 'color-observatory-500'
        }
      };
    }
  }
};

export const cycleToInterval = (cycle) => {
  const start = dayjs(cycle.tanggal).format('D MMM YYYY');
  const end = cycle.final_harvest
    ? `${dayjs(cycle.final_harvest.tanggal).format('D MMM YYYY')} (Panen)`
    : 'Sekarang';
  return `${start} — ${end}`;
};

export const formatNumber = (number) => {
  return Number(number).toLocaleString(['ban', 'id']);
};

export const useFlags = () => {
  const context = React.useContext(FlagsContext);
  return context;
};

export const flaggedWaterParams = (parameters, flag) => {
  if (!flag) {
    return parameters
      .map((el) => {
        if (el.label !== 'Nitrit' && el.label !== 'Amonia') {
          return el;
        }
      })
      .filter((el) => typeof el !== 'undefined');
  } else {
    return parameters;
  }
};

export const validationRegexes = (inputValue, type) => {
  const textRegex = '^[a-zA-Z ]*$';
  const numberRegex = '^[0-9]*$';
  let phoneNumber;
  switch (type) {
    case 'phone':
      phoneNumber = parsePhoneNumber(inputValue, 'ID');
      if (inputValue !== '' && phoneNumber && inputValue.charAt(0) === '0')
        return phoneNumber.isValid();
      break;
    case 'text':
      return !new RegExp(textRegex, 'gm').test(inputValue);
    default:
      return !new RegExp(numberRegex, 'gm').test(inputValue);
  }
};

export const closeTooltip = () => {
  let tooltipClass = document.getElementsByClassName('hovertext');
  for (let i = 0; i < tooltipClass.length; i++) {
    tooltipClass[i].style.display = 'none';
  }
};

export const numberize = (value) => {
  if (Array.isArray(value)) {
    return value.map((item) => numberize(item));
  }

  if (value === null) {
    return value;
  }

  if (typeof value === 'object') {
    const result = {};
    for (const [key, item] of Object.entries(value)) {
      result[key] = numberize(item);
    }
    return result;
  }

  if (typeof value === 'string') {
    // https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number#comment32052139_175787
    return +value === +value ? Number(value) : value;
  } else {
    return value;
  }
};

export const groupCycleIdentity = (selectedCycle, selectedPond) => {
  const cycle = [];
  cycle.push(
    {
      title: 'Komoditas',
      desc: selectedCycle?.komoditas
    },
    {
      title: 'Jenis Budidaya',
      desc:
        selectedCycle?.jenis_budidaya?.replace(/_/g, ' ') || 'Tidak ada data'
    },
    {
      title: 'Tanggal Tebar',
      desc: dayjs(selectedCycle?.tanggal).locale('id').format('DD MMMM YYYY')
    },
    {
      title: 'Tanggal Panen',
      desc: selectedCycle?.final_harvest
        ? dayjs(selectedCycle?.final_harvest.tanggal).format('DD MMMM YYYY')
        : 'Belum Panen'
    },
    {
      title: 'Jumlah Benih',
      desc: selectedCycle?.jumlah_benih?.toLocaleString(['ban', 'id'])
    },
    {
      title: 'Asal Benih',
      desc: selectedCycle?.asal_benih
    },
    {
      title: 'Rataan Ukuran Benih',
      desc: selectedCycle?.ukuran_benih + ' cm'
    },
    {
      title: 'Rataan Berat Benih',
      desc: selectedCycle?.berat_benih + ' gr'
    },
    {
      title: 'Berat Total Benih',
      desc: selectedCycle?.berat_benih * selectedCycle?.jumlah_benih + ' kg'
    },
    {
      title: 'Padat Tebar',
      desc: selectedPond?.area
        ? (selectedCycle?.jumlah_benih / selectedPond?.area).toFixed(2) + '/m²'
        : 'Tidak ada data'
    },
    {
      title: 'Luas Kolam',
      desc: selectedPond?.area ? selectedPond?.area + ' m²' : 'Tidak ada data'
    },
    {
      title: 'Dosis Awal Akualisan',
      desc: selectedCycle?.starting_aqualisan_dose
        ? selectedCycle?.starting_aqualisan_dose + ' Liter/m³'
        : 'Tidak ada data'
    }
  );
  return cycle;
};

export const ObjectString = (Object.byString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
});

export const generateRangeDate = (start_date, end_date) => {
  let formatedStartDate, formatedEndDate;
  let startDateYear = dayjs(start_date).year();
  let endDateYear = dayjs(end_date).year();

  if (startDateYear === endDateYear) {
    formatedStartDate = dayjs(start_date).locale('id').format('DD MMM');
    formatedEndDate = dayjs(end_date).locale('id').format('DD MMM YYYY');
    return `${formatedStartDate} - ${formatedEndDate}`;
  }

  formatedStartDate = dayjs(start_date).locale('id').format('DD MMM YYYY');
  formatedEndDate = dayjs(end_date).locale('id').format('DD MMM YYYY');
  return `${formatedStartDate} - ${formatedEndDate}`;
};

export const formatRupiah = (currencyIndonesian) => {
  if (!Number(currencyIndonesian)) {
    return '';
  }

  const format = currencyIndonesian.toString().split('').reverse().join('');
  const convert = format.match(/\d{1,3}/g);
  const rupiah = `${convert.join('.').split('').reverse().join('')}`;
  return rupiah;
};

export const formatCurrency = (number) => {
  const formatter = new Intl.NumberFormat('id');
  return formatter.format(number);
};

export const phoneReplaceNormalize = (phone) => {
  phone = String(phone).trim();
  if (phone.startsWith('+62')) {
    phone = '0' + phone.slice(3);
  } else if (phone.startsWith('62')) {
    phone = '0' + phone.slice(2);
  }
  return phone.replace(/[- .]/g, '');
};

export const phoneInvertNormalize = (phone) => {
  if (phone.startsWith('0')) {
    return '+62' + phone.slice(1);
  }
  if (phone.startsWith('62')) {
    return '+' + phone;
  }
  return phone;
};

export const getUrlFeatureByUtmCampaign = (utmCampaign) => {
  let redirectUrl = '/';

  if (utmCampaign === belajar_budidaya) redirectUrl = '/learn-cultivation/list';
  if (utmCampaign === konsultasi_budidaya) redirectUrl = '/consultation/wa';
  if (utmCampaign === rencana_panen) redirectUrl = '/shrimp-harvest';
  if (utmCampaign === kalkulator_budidaya)
    redirectUrl = '/cultivation-calculator';
  if (utmCampaign === toko_budidaya) redirectUrl = '/marketplace';
  if (utmCampaign === DEEP_LINK_CREATE_FARM) {
    redirectUrl = '/my-ponds/farms/create';
  }

  return redirectUrl;
};

export const goToFeature = (history, utmCampaign, utmRoute) => {
  const redirectUrl = getUrlFeatureByUtmCampaign(utmCampaign);

  trackerLandingPage.trackDeeplink(utmCampaign);
  removeUtmCampaign();
  removeUtmRoute();
  history.push(utmRoute || redirectUrl);
};

export const generateDayFromNowTo = (days = 1) => {
  const dt = new Date();
  dt.setDate(dt.getDate() + days);
  return dt;
};

//TO DO : FOR testing purpose in Staging & UAT. Using in Prod Will be discuss before deployment
export const isShowedMarketplaceIconByProvince = (province) => {
  if (!province) return false;
  const excludeProvinces = config.marketplace.excludeProvince;

  return !excludeProvinces.some(
    // 0 -> it means exclude for all provinces
    (excludeProvince) =>
      excludeProvince === '0' || province.toString() === excludeProvince
  );
};

export const isLocalhost =
  location.hostname === 'localhost' ||
  location.hostname === '127.0.0.1' ||
  process.env.NODE_ENV === 'development';

export const debounce = (callback, time = 300) => {
  let debounceTimer;

  const debouncer = (...args) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      callback(...args);
    }, time);
  };

  return debouncer;
};
