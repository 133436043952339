import * as efiauth from 'libs/efiauth';
import axios from 'axios';
import packageJson from 'package-json';

import { getToolsbudToken } from '@cultivation-records/store/reportExternal';

const baseUrlCultivationService = process.env.REACT_APP_CULTIVATION_API_URL;

const setHeaderCultivation = {
  baseURL: baseUrlCultivationService,
  setHeaders: (headers, token) => {
    const toolsbudToken = getToolsbudToken();

    headers['Authorization'] = `Bearer ${
      toolsbudToken ? toolsbudToken : token
    }`;
    headers['X-Client-Id'] = toolsbudToken ? 'TOOLS_BUDIDAYA_V1' : 'EFARM';
  }
};

export const apiCultivation = efiauth.createAxios(setHeaderCultivation);

const instanceTokenOpts = {
  setHeaders: (headers, token) => {
    headers['Authorization'] = `Bearer ${token}`;
  }
};

export const instanceWithToken = efiauth.createAxios(
  efiauth.isRefreshTokenEnabled ? {} : instanceTokenOpts
);

export const instanceWithTokenCustomer = efiauth.createAxiosCustomer(
  efiauth.isRefreshTokenEnabled ? {} : instanceTokenOpts
);

export const instanceWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'X-Client-Id': 'efarm',
    'X-Client-Version': packageJson.version
  }
});

export const instanceCustomHeaders = (method, endpoint, headers, payload) => {
  let config = {
    method,
    url: `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
    headers,
    withCredentials: efiauth.isRefreshTokenEnabled
  };
  if (payload) Object.assign(config, { data: payload });
  return axios(config);
};

export const request = async (requester) => {
  try {
    const res = await requester();
    return res?.data?.data;
  } catch (err) {
    if (err.response) {
      err.message = `Error HTTP ${err.response.status}`;
      if (err.response.status === 401) {
        efiauth.doLogout();
      }
    }
    throw err;
  }
};
export const requestWithData = async (requester) => {
  try {
    const res = await requester();
    return res?.data;
  } catch (err) {
    if (err.response) {
      err.message = `Error HTTP ${err.response.status}`;
      if (err.response.status === 401) {
        efiauth.doLogout();
      }
    }
    throw err;
  }
};
