import { Button } from 'reactstrap';
import useWindowDimensions from 'hooks/useWindowDimensions';
import logoeFarm from 'assets/logo-efarm.webp';
import config from 'config';
import classNames from 'classnames';
import { isDownloadPromptVisible } from 'libs/helpers';
import { useRouteMatch } from 'react-router-dom';

const DownloadPrompt = () => {
  const isInProhibitedPage = config.downloadBar.prohibitedRoutes.some(
    (route) => useRouteMatch(route)?.isExact || false
  );
  const windowDimension = useWindowDimensions();
  const viewportWidth = windowDimension.width;
  return (
    isDownloadPromptVisible &&
    !isInProhibitedPage && (
      <div className="flex-row-only justify-center align-center fixed-bottom p-3 bg-white border-top-corner-radius box-shadow">
        <div>
          <img width={32} height={32} src={logoeFarm} alt="logo eFarm" />
        </div>
        <div className="mx-2">
          <div
            className={classNames(
              viewportWidth > 395 ? 'size-font-200' : 'size-font-100',
              'font-weight-medium'
            )}
          >
            Download aplikasi eFarm, yuk!
          </div>
          <div
            className={classNames(
              viewportWidth > 395 ? 'size-font-100' : 'size-font-10'
            )}
          >
            Biar bisa buka dan akses semua fiturnya dengan mudah.
          </div>
        </div>
        <div>
          <a
            href={config.playStoreLink}
            target="_blank"
            rel="noreferrer"
            id="btn-download-playstore"
            data-action="utm/playstore"
          >
            <Button color="primary">Download</Button>
          </a>
        </div>
      </div>
    )
  );
};

DownloadPrompt.displayName = 'DownloadPrompt';
export default DownloadPrompt;
