import { getLeadsState } from 'store/leads';

export const disableTargeting = () => {
  // Survicate: Disable automatic targeting
  // referensi:
  // 1. https://help.survicate.com/en/articles/3937822-show-your-survey-after-a-javascript-event
  // 2. https://developers.survicate.com/javascript/configuration/?_gl=1*1x4x1mb*_ga*MTI3NzYzOTEyOS4xNjQzOTYzNTA2*_ga_ZRE7VLNMPF*MTY0OTIzNTA5NC4yNS4xLjE2NDkyMzY5NDEuMA..#disable-automatic-targeting
  (function (opts) {
    opts.disableTargeting = true;
  })((window._sva = window._sva || {}));
};

export const getSurveyId = ({ surveyName }) => {
  const surveyIds = JSON.parse(process.env.REACT_APP_SURVICATE_ID) || {};
  return surveyIds[surveyName] || '';
};

export const displaySurvey = ({
  surveyId = '',
  options = {
    forceDisplay: true,
    displayMethod: 'immediately'
  }
}) => {
  const survicate = window._sva;
  if (!survicate) return false;

  const {
    lead_id = '',
    email = '',
    name = '',
    phone = ''
  } = getLeadsState() || {};

  if (survicate) {
    survicate.setVisitorTraits({
      email,
      name,
      phone,
      lead_id
    });
    survicate.showSurvey(surveyId, options);

    return true;
  }
};

export const questionAnswered = (callback) => {
  const survicate = window._sva;
  if (survicate) {
    survicate.addEventListener(
      'question_answered',
      (surveyId, questionId, answer) => {
        callback({ surveyId, questionId, answer });
      }
    );
  }
};

export const closeSurvey = (callback) => {
  const survicate = window._sva;
  if (survicate) {
    survicate.addEventListener('survey_closed', (surveyId) =>
      callback(surveyId)
    );
  }
};

export const getStateSurvey = () => {
  const survicate = window._sva || {};
  const { getState = () => {} } = survicate;

  if (!getState()) return false;
  const { visit = {}, visitor = {} } = getState() || {};
  return {
    visit,
    visitor
  };
};
