import { FC, memo, useContext, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'reactstrap';

// local path
import { Wrapper, Dismiss } from './styles';
import './snackbar.scss';

// global path
import { SnackbarContext } from 'contexts/SnackbarContext';
import Typography from 'components/reusable/Typography';

interface SnackbarProps {
  hideCloseButton?: boolean;
  hideConfirmButton?: boolean;
}

const Snackbar: FC<SnackbarProps> = ({ hideCloseButton = false }) => {
  const { isDisplayed, isHideConfirmButton, message, onClose, onConfirm } =
    useContext(SnackbarContext);

  const snackbarRef = useRef(null);
  const container = document.getElementById('snackbarPortal') as HTMLElement;

  return createPortal(
    <CSSTransition
      in={isDisplayed}
      classNames="slide-up"
      nodeRef={snackbarRef}
      timeout={300}
      unmountOnExit
    >
      <Wrapper ref={snackbarRef}>
        <Typography color="white" variant="200" weight={400}>
          {message}
        </Typography>

        {!hideCloseButton && <Dismiss onClick={onClose}>&times;</Dismiss>}

        {!isHideConfirmButton && <Button onClick={onConfirm}>Oke</Button>}
      </Wrapper>
    </CSSTransition>,
    container
  );
};

export default memo(Snackbar);
