import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  background-color: #4a4a4a;
  border-radius: 8px;
  bottom: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  left: 0;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  overflow: hidden;
  padding: 12px 16px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 9999999;

  .btn-secondary {
    background-color: transparent;
    border-color: transparent;
    border: 0px;
    margin-left: 16px;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none !important;
      border: 0px !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }

  @media screen and (min-width: 500px) {
    max-width: 480px;
  }
`;

export const Dismiss = styled.div`
  color: #ae7ee9;
  cursor: pointer;
  font-weight: bold;
`;
