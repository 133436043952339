import { ICTExtends, IOnActionVisitUrl, IPopupObject } from '../interfaces';

export function onActionVisitUrl(props: IOnActionVisitUrl) {
  const { url, onNewTab } = props;
  if (!url) return false;
  if (url === 'doNothing') return true;
  if (onNewTab) {
    window.open(url);
  } else {
    window.location.href = url;
  }
  return true;
}

export const onActionClicked = (
  clevertap: ICTExtends,
  popupObject: IPopupObject
) => {
  const { msgId, pivotId, data } = popupObject;
  const success = onActionVisitUrl({
    url: data?.url as string,
    onNewTab: Boolean(data?.onNewTab)
  });
  if (success && !data?.['localTest']) {
    clevertap.renderNotificationClicked?.({ msgId, pivotId });
  }
};
