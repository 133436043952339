import clevertap from 'clevertap-web-sdk';
import {
  ICTExtends,
  ICTPopupMessage,
  IClevertapContext,
  IClevertapContextProps,
  IPopupObject
} from '../interfaces';
import { createContext, useEffect, useState } from 'react';
import ClevertapPopup from '../components/PopupModal';
import {
  getImageSource,
  getPopupStyle,
  getTemplateType,
  normalizeKV,
  onActionClicked
} from '../helpers';
import { validTemplateType } from '../constant';
import { ConfigEnv } from 'libs/env';

// import msgJSON from '../constant/mock-popup-message.json';

export const ClevertapContext = createContext<IClevertapContextProps | null>(
  null
);

export function ClevertapProvider({ children, ...props }: IClevertapContext) {
  const {
    clevertap: { customPopupFeature }
  } = ConfigEnv;
  return (
    <>
      {customPopupFeature ? (
        <ClevertapPopupProvider {...props}>{children}</ClevertapPopupProvider>
      ) : (
        <ClevertapContext.Provider
          value={{ isActive: false, notificationObject: null }}
          {...props}
        >
          {children}
        </ClevertapContext.Provider>
      )}
    </>
  );
}

function ClevertapPopupProvider({ children, ...props }: IClevertapContext) {
  const clevertapExt: ICTExtends = clevertap;
  const [notificationObj, setNotificationObj] = useState<IPopupObject | null>(
    null
  );
  const [popupOpen, setPopupOpen] = useState(false);
  clevertapExt.notificationCallback = (message: ICTPopupMessage) => {
    const {
      msgContent: { type, html, title, description, templateType, kv },
      msgId,
      pivotId
    } = message;

    // get ImageSrc if present
    const notifObject: IPopupObject = {
      msgId,
      pivotId,
      title,
      description,
      type,
      templateType: getTemplateType({ templateType, kv }),
      imgSrc: getImageSource({ templateType, html }),
      data: normalizeKV(kv),
      style: getPopupStyle({ templateType, html })
    };

    setNotificationObj(notifObject);
    // trigger Popup Viewed Event when the template valid
    if (
      validTemplateType.includes(notifObject.templateType) &&
      !kv?.['localTest']
    )
      clevertapExt.renderNotificationViewed?.({ msgId, pivotId });
  };

  clevertapExt.onActionClicked = onActionClicked;

  const value = {
    isActive: true,
    notificationObject: notificationObj
  };

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
    setNotificationObj(null);
  };

  useEffect(() => {
    if (notificationObj) {
      setPopupOpen(true);
    }
  }, [notificationObj]);

  // un-comment this line below for local testing purposses
  // useEffect(() => {
  //   clevertapExt.notificationCallback?.(msgJSON.interstitial as any);
  // }, []);

  return (
    <ClevertapContext.Provider value={value} {...props}>
      {children}
      {notificationObj && (
        <ClevertapPopup
          clevertap={clevertapExt}
          openState={popupOpen}
          notificationObject={notificationObj as IPopupObject}
          toggleClose={togglePopup}
        />
      )}
    </ClevertapContext.Provider>
  );
}
