export const FlagsEnv = {
  isDev: process.env.REACT_APP_ENV === 'development',
  isStaging: process.env.REACT_APP_ENV === 'staging',
  isProduction: process.env.REACT_APP_ENV === 'production',
  isUat: process.env.REACT_APP_ENV === 'uat'
};

export const ConfigEnv = {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? 'null',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? 'null',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? 'null',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? 'null',
    messagingSenderId:
      process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? 'null',
    appId: process.env.REACT_APP_FIREBASE_APP_ID ?? 'null',
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? 'null'
  },
  analytics: {
    gtmId: process.env.REACT_APP_GTM_ID ?? '',
    fbPixelId: process.env.REACT_APP_FB_PIXEL_ID ?? '',
    appsFlyerId: process.env.REACT_APP_APPS_FLYER_ID ?? ''
  },
  clevertap: {
    projectId: process.env.REACT_APP_CLEVERTAP_PROJECT_ID ?? '',
    region: process.env.REACT_APP_CLEVERTAP_REGION ?? '',
    customPopupFeature: process.env.REACT_APP_FEATURE_CLEVERTAP_POPUP === '1'
  }
};
