import { useCallback, useState, createContext, ReactNode, FC } from 'react';

import PopupAlert from 'components/reusable/PopupAlert';

interface IAlertProviderProps {
  children: ReactNode;
}

interface IAlertContext {
  message: string;
  showAlert: boolean;
  handleShowAlert: (msg: string, type: string) => void;
}

export const defaultValueAlertContext = {
  message: '',
  showAlert: false,
  handleShowAlert: () => {}
};

export const AlertContext = createContext<IAlertContext>(
  defaultValueAlertContext
);

export const AlertProvider: FC<IAlertProviderProps> = ({
  children,
  ...props
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [typeAlert, setTypeAlert] = useState('');
  const [showIcon, setShowIcon] = useState(false);

  const handleShowAlert = useCallback((msg, type, icon = true) => {
    setShowAlert(true);
    setMessage(msg);
    setTypeAlert(type);
    setShowIcon(icon);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setShowAlert(false);
    setMessage('');
    setTypeAlert('');
    setShowIcon(false);
  }, []);

  const value = {
    handleShowAlert,
    message,
    showAlert
  };

  return (
    <AlertContext.Provider value={value} {...props}>
      <PopupAlert
        isOpen={showAlert}
        onClose={handleCloseAlert}
        message={message}
        variant={typeAlert}
        showIcon={showIcon}
      />
      {children}
    </AlertContext.Provider>
  );
};
