const UTM_CAMPAIGN_KEY = 'utm-campaign';
const UTM_ROUTE_KEY = 'utm-route';

export const storeUtmCampaign = (content) => {
  localStorage.setItem(UTM_CAMPAIGN_KEY, content);
};

export const getUtmCampaign = () => {
  return localStorage.getItem(UTM_CAMPAIGN_KEY);
};

export const removeUtmCampaign = () => {
  localStorage.removeItem(UTM_CAMPAIGN_KEY);
};

export const storeUtmRoute = (content) => {
  localStorage.setItem(UTM_ROUTE_KEY, content);
};

export const getUtmRoute = () => {
  return localStorage.getItem(UTM_ROUTE_KEY);
};

export const removeUtmRoute = () => {
  localStorage.removeItem(UTM_ROUTE_KEY);
};
