import imgBenefit from 'assets/img/shrimp-harvest/benefit-1.svg';
import imgBenefit2 from 'assets/img/shrimp-harvest/benefit-2.svg';
import imgBenefit3 from 'assets/img/shrimp-harvest/benefit-3.svg';
import mamang from 'assets/img/dashboard/consultation/mamang.webp';
import sapto from 'assets/img/dashboard/consultation/sapto.webp';
import syarifa from 'assets/img/dashboard/consultation/syarifa.webp';
import consultationChatViaApp from 'assets/img/dashboard/consultation/chat-via-wa.svg';
import consultationFreeConsultation from 'assets/img/dashboard/consultation/free-consultation.svg';
import consultationWithExpert from 'assets/img/dashboard/consultation/with-expert.svg';

// WIP REFACTOR MOVE WANUMBER TO FIREBASE
const waNumber = '6281359593535';
const joinNumber = '6281385545425';

const config = {
  water: {
    parameters: [
      {
        label: 'pH',
        chartValues: [
          {
            limits: [
              {
                value: 8,
                name: 'Ambang Batas'
              },
              7.5
            ],
            range: [7, 9.5],
            title: 'Pagi',
            name: 'pH Pagi',
            yKey: 'ph_pagi'
          },
          {
            limits: [
              {
                value: 8.4,
                name: 'Ambang Batas'
              },
              8
            ],
            range: [7, 9.5],
            title: 'Sore',
            name: 'pH Sore',
            yKey: 'ph_sore'
          },
          {
            limits: [
              {
                value: 0.5,
                name: 'Batas Atas'
              }
            ],
            title: 'Range pH',
            name: 'Range pH',
            yKey: 'fluktuasi_ph'
          }
        ]
      },
      {
        label: 'Nitrit',
        limits: [
          {
            value: 0.5,
            name: 'Batas Atas Nitrit'
          }
        ],
        chartValues: 'nitrite',
        name: 'Nitrit'
      },
      {
        label: 'DO',
        chartValues: [
          {
            title: 'Pagi',
            limits: [
              {
                value: 4,
                name: 'Batas Bawah DO'
              }
            ],
            name: 'DO Pagi',
            yKey: 'do_pagi'
          },
          {
            title: 'Sore',
            limits: [
              {
                value: 4,
                name: 'Batas Bawah DO'
              }
            ],
            name: 'DO Sore',
            yKey: 'do_sore'
          }
        ]
      },
      {
        label: 'Salinitas',
        limits: [
          {
            value: 25,
            name: 'Batas Atas Salinitas'
          },
          15
        ],
        chartValues: 'salinty',
        name: 'Salinitas'
      },
      {
        label: 'Suhu',
        chartValues: [
          {
            title: 'Pagi',
            limits: [
              {
                value: 30,
                name: 'Ambang Batas'
              },
              27
            ],
            yKey: 'suhu_pagi',
            name: 'Suhu Pagi'
          },
          {
            title: 'Sore',
            limits: [
              {
                value: 30,
                name: 'Ambang Batas'
              },
              27
            ],
            yKey: 'suhu_sore',
            name: 'Suhu Sore'
          }
        ]
      },
      {
        label: 'Amonia',
        name: 'Amonia',
        limits: [
          {
            value: 0.1,
            name: 'Batas Atas Amonia'
          }
        ],
        chartValues: 'ammonia'
      },
      {
        label: 'Alkalinitas',
        name: 'Alkalinitas',
        limits: [
          {
            value: 150,
            name: 'Ambang Batas'
          },
          100
        ],
        chartValues: 'total_alkalinitas'
      },
      {
        label: 'Mg',
        name: 'Mg',
        limits: [
          {
            value: 4000,
            name: 'Ambang Batas'
          },
          2000
        ],
        chartValues: 'mg'
      },
      {
        label: 'Ca',
        name: 'Ca',
        limits: [
          {
            value: 3000,
            name: 'Ambang Batas'
          },
          1500
        ],
        chartValues: 'ca'
      }
    ]
  },
  feedLog: {
    parameters: [
      {
        title: 'Kumulasi',
        value: 'amount_kg',
        dataKey: 'feed_cumulative',
        name: 'Kumulasi Pakan',
        dashboardKey: 'computed'
      },
      {
        title: 'Harian',
        value: 'berat_total',
        dataKey: 'feeding',
        name: 'Pengeluaran Pakan/Hari',
        dashboardKey: 'data'
      }
    ]
  },
  growth: {
    parameters: [
      {
        title: 'ABW',
        name: 'Rataan Pertumbuhan Harian',
        value: 'weight_gram',
        dataKey: 'abw',
        dashboardKey: 'computed'
      },
      {
        title: 'ADG',
        name: 'Rataan Pertumbuhan Harian',
        value: 'adg',
        dataKey: 'abw',
        dashboardKey: 'computed'
      }
    ]
  },
  chart: {
    pie: {
      markerColors: [
        'rgb(53, 159, 133)',
        'rgb(255, 228, 0)',
        'rgb(212, 65, 64)',
        'rgb(93, 199, 227)',
        'rgb(192, 87, 23)'
      ]
    }
  },
  form: {
    checkbox: {
      source: [
        { id: '1', label: 'Webinar', value: 'webinar' },
        { id: '2', label: 'Youtube', value: 'youtube' },
        { id: '3', label: 'Instagram', value: 'instagram' },
        { id: '4', label: 'Tiktok', value: 'tiktok' },
        { id: '5', label: 'Facebook', value: 'facebook' },
        { id: '6', label: 'WA / Telegram / FB Group', value: 'wa_group' },
        { id: '7', label: 'WA', value: 'wa' },
        { id: '8', label: 'Google', value: 'google' },
        { id: '9', label: 'Majalah', value: 'majalah' },
        { id: '10', label: 'Berita online', value: 'online_news' },
        { id: '11', label: 'Kunjungan team eFishery', value: 'offline_visit' },
        { id: '12', label: 'Acara yang diadakan eFishery', value: 'event' },
        { id: '13', label: 'Lainnya', value: 'other' }
      ]
    },
    radio: {
      roles: [
        'Saya tidak bekerja / tidak memiliki tambak udang',
        'Pemilik',
        'Manager',
        'Admin',
        'Teknisi',
        'Anak Kolam',
        'Bakul',
        'Agen',
        'Lainnya'
      ]
    },
    options: {
      role: [
        {
          label: 'Owner/pemilik',
          value: 'Owner/pemilik'
        },
        {
          label: 'Teknisi',
          value: 'Teknisi'
        },
        {
          label: 'Manager Tambak',
          value: 'Manager Tambak'
        },
        {
          label: 'Bakul',
          value: 'Bakul'
        },
        {
          label: 'Processing',
          value: 'Processing'
        },
        {
          label: 'Akademisi',
          value: 'Akademisi'
        },
        {
          label: 'Pelaku industri',
          value: 'Pelaku industri'
        },
        {
          label: 'Lainnya',
          value: 'Lainnya'
        }
      ],
      seedCount: [
        '>200 ekor /m2',
        '>100-200 ekor /m2',
        '50-120 ekor /m2',
        '<50 ekor /m2'
      ],
      feedPrice: ['', 'Pakan alami', '<10.000', '11.000-40.000', '>40.000'],
      harvestSize: ['', '10-50', '50-100', '100-200', '200-1000', '1000-2000'],
      cultivationType: [
        {
          label: 'Ya',
          value: 'udang'
        },
        {
          label: 'Tidak',
          value: 'lainnya'
        }
      ]
    }
  },
  whatsapp: {
    url: `https://api.whatsapp.com/send?phone=${waNumber}`,
    joinNumberUrl: `https://api.whatsapp.com/send?phone=${joinNumber}`,
    joinNumber
  },
  harvestShrimpBenefit: [
    {
      image: imgBenefit,
      text: 'Anda bisa mendapatkan insentif yang menarik dari kami!'
    },
    {
      image: imgBenefit2,
      text: 'Dapatkan gratis layanan \n konsultasi budidaya'
    },
    {
      image: imgBenefit3,
      text: 'Anda bisa mendapatkan gratis layanan pendampingan'
    }
  ],
  harvestShrimpBanner: [
    {
      text: 'Dapatkan diskon hingga 40% Feeder dan probiotik udang untuk tingkatkan produktivitas kolam'
    },
    {
      text: 'Dapatkan gratis layanan \nkonsultasi budidaya'
    },
    {
      text: 'Anda bisa mendapatkan gratis \nlayanan pendampingan'
    }
  ],
  login: {
    benefits: [
      'Laporan kualitas air harian (fisika & kimia)',
      'Informasi dan rekomendasi tiap kolam',
      'Laporan budidaya 1 siklus',
      'Konsultasi budidaya'
    ]
  },
  sellShrimp: {
    form: {
      harvestType: [
        {
          label: 'Parsial',
          value: 'PARTIAL'
        },
        {
          label: 'Total',
          value: 'TOTAL'
        }
      ],
      source: [
        'Youtube',
        'WA / Telegram',
        'FB',
        'Tiktok',
        'Google',
        'Tim lapangan eFishery'
      ],
      questions: {
        'Saya sudah memiliki kontrak dengan pembeli lain': null,
        'Saya tertarik jika udang saya dibeli oleh eFishery': null,
        'Saya memiliki tim sortir': null,
        'Saya memiliki tim panen': null,
        'Saya memiliki tim logistik': null
      }
    }
  },
  questionnaire: {
    interest: [
      'Ingin belajar budidaya',
      'Ingin mendapat update seputar pasar udang',
      'Ingin mendapat update tentang produk sarana budidaya udang',
      'Lainnya'
    ]
  },
  downloadBar: {
    prohibitedRoutes: [
      '/summary/all',
      '/acquisition-form',
      '/profile/edit',
      '/shrimp-harvest**',
      '/cycle',
      '/summary/:id/:id',
      '/cultivation-calculator**',
      '/my-ponds**',
      '/marketplace**',
      '/location-picker',
      '/event-cultivation/list',
      '/event-cultivation/ticket-list',
      '/event-cultivation/detail**',
      '/cultivation/records/**',
      '/cultivation/daily-report/**',
      '/cultivation/**',
      '/join-mentoring-member',
      '/acquisition-form/success'
    ]
  },
  marketplace: {
    excludeProvince:
      process.env.REACT_APP_MARKETPLACE_EXCLUDE_PROVINCE_ID?.split(',') ?? [],
    excludeCategory: []
  },
  dashboard: {
    consultationValues: [
      {
        title: 'Konsultasi Gratis',
        desc: 'Diskusi semuanya tentang budidaya tanpa pungutan biaya apapun.',
        img: consultationFreeConsultation
      },
      {
        title: 'Langsung dengan Ahlinya',
        desc: 'Konsultasi budidaya mulai dari pH air hingga penyakit udang dengan pakarnya.',
        img: consultationWithExpert
      },
      {
        title: 'Chat via WhatsApp',
        desc: 'Ahli kami akan selalu siap melayani kamu.',
        img: consultationChatViaApp
      }
    ],
    consultationTesti: [
      {
        name: 'Sapto Triyadi',
        location: 'Kebumen',
        img: sapto,
        testi:
          'Jadi paham cara mengobati penyakit WFD serta berhasil menurunkan dari adanya infeksi penyakit WFD.'
      },
      {
        name: 'Syarifa Mayly',
        location: 'Sumatera Utara',
        img: syarifa,
        testi:
          'Setelah melakukan konsultasi masalah kematian udang karena kres plankton, kini paham untuk mengatasi dan mengembalikan kondisi air menjadi stabil agar stres pada udang menurun.'
      },
      {
        name: 'Mamang',
        location: 'Sumenep Madura',
        img: mamang,
        testi:
          'Melakukan konsultasi intens sehingga bisa lolos dari mortality, panennya pun menjadi memuaskan dibandingkan penambak daerah sekitar yang banyak terjadi gagal panen.'
      }
    ],
    consultationMessage:
      'Halo, saya ingin bertanya seputar budidaya udang atau produk eFishery terkait tambak udang'
  },
  tawkTo: {
    propertyId: process.env.REACT_APP_TAWK_TO_PROPERTY_ID,
    widgetId: process.env.REACT_APP_TAWK_TO_WIDGET_ID
  },
  playStoreLink:
    'https://play.google.com/store/apps/details?id=com.efishery.farmerapps&referrer=utm_source%3Dbtn_install_web_farmefishery_22%2520Juni%252022%26utm_medium%3Dbtn_install_web_farmefishery_22%2520Juni%252022%26utm_term%3Dbtn_install_web_farmefishery_22%2520Juni%252022%26utm_content%3Dbtn_install_web_farmefishery_22%2520Juni%252022%26utm_campaign%3Dbtn_install_web_farmefishery_22%2520Juni%252022%26anid%3Dadmob'
};

export default config;
