const LOCATION_STATE_STORAGE_KEY = 'location-state';

export const getLocationState = () => {
  let state =
    JSON.parse(localStorage.getItem(LOCATION_STATE_STORAGE_KEY)) || '';

  if (state !== '') {
    const defaultAddress = `${state?.province_name}, ${state?.city_name}, ${state?.district_name}, ${state?.subdistrict_name}`;
    state.isRequiredAddressDetail =
      state?.name === defaultAddress || state?.name === '';
  }

  return state;
};

export const setLocationState = (state) => {
  localStorage.setItem(LOCATION_STATE_STORAGE_KEY, JSON.stringify(state));
};

export const editLocationState = (key, value) => {
  const state = getLocationState();
  state[key] = value;
  setLocationState(state);
};

export const deleteLocationState = () => {
  localStorage.removeItem(LOCATION_STATE_STORAGE_KEY);
};
