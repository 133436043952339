const ACQUSISITION_STATE_STORAGE_KEY = 'acquisition-state';
const ACQUSISITION_DUMMY_STORAGE_KEY = 'is_dummy';

export const getAcquisitionState = () => {
  const state =
    JSON.parse(localStorage.getItem(ACQUSISITION_STATE_STORAGE_KEY)) || '';
  return state;
};

export const setAcquisitionState = (state) => {
  localStorage.setItem(ACQUSISITION_STATE_STORAGE_KEY, JSON.stringify(state));
};

export const editAcquisitionState = (key, value) => {
  const state = getAcquisitionState();
  state[key] = value;
  setAcquisitionState(state);
};

export const deleteAcquisitionState = () => {
  localStorage.removeItem(ACQUSISITION_STATE_STORAGE_KEY);
};

export const getDummyState = () => {
  const state = localStorage.getItem(ACQUSISITION_DUMMY_STORAGE_KEY);
  return state === 'true';
};

export const setDummyState = () => {
  localStorage.setItem(ACQUSISITION_DUMMY_STORAGE_KEY, 'true');
};

export const deleteDummyState = () => {
  localStorage.removeItem(ACQUSISITION_DUMMY_STORAGE_KEY);
};
