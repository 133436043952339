/* eslint-disable indent */
import axios from 'axios';
import mem from 'mem/dist';
import {
  storeCoachmarkDashboardFlag,
  getCoachmarkDashboardFlag
} from 'store/dashboard';
import {
  deletePersistedHistory,
  deletePersistedHistoryDetail,
  deleteStateBenefitShrimp
} from 'store/shrimpHarvest';
import {
  removePersistedName,
  removePersistedTokens,
  removePersistedExpiryTokenTime,
  loadPersistedExpiryTokenTime,
  storePersistedTokens,
  storePersistedExpiryTokenTime,
  loadPersistedTokens
} from 'store/auth';
import { deleteLeadsState } from 'store/leads';
import { deleteLocationState } from 'store/location';
import { deleteDummyState, deleteAcquisitionState } from 'store/acquisition';
import packageJson from 'package-json';

import { removeToolsbudToken } from '@cultivation-records/store/reportExternal';

const DEFAULT_OVERHEAD_SECOND = 10;

let authsUrl = process.env.REACT_APP_BACKEND_URL;
let accessToken = null;
let accessTokenExpiryTime = null;
let refreshToken = null;
let refreshTokenExpiryTime = null;

export class InvalidTokenError extends Error {}
export class AuthServiceError extends Error {}

// <getters-and-setters>

export const initialize = (config = {}) => {
  authsUrl = config.authsUrl;
};

export const getAccessToken = () => {
  return accessToken;
};

export const setAccessToken = (newAccessToken) => {
  accessToken = newAccessToken;
};

export const getRefreshToken = () => {
  return refreshToken;
};

export const setRefreshToken = (newRefreshToken) => {
  refreshToken = newRefreshToken;
};

export const getAccessTokenExpiryTime = () => {
  return accessTokenExpiryTime;
};

export const setAccessTokenExpiryTime = (newAccessTokenExpiryTime) => {
  accessTokenExpiryTime = newAccessTokenExpiryTime;
};

export const getRefreshTokenExpiryTime = () => {
  return refreshTokenExpiryTime;
};

export const setRefreshTokenExpiryTime = (newRefreshTokenExpiryTime) => {
  refreshTokenExpiryTime = newRefreshTokenExpiryTime;
};

export const checkExpired = (
  overheadSecond = DEFAULT_OVERHEAD_SECOND,
  expiryTime
) => {
  const now = new Date().getTime() / 1000;
  return now + overheadSecond > expiryTime;
};

export const setTokens = (accessToken, refreshToken) => {
  if (!isRefreshTokenEnabled) {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    storePersistedTokens({
      access_token: getAccessToken(),
      refresh_token: getRefreshToken()
    });
  }
};

export const setTokenExpiryTime = (
  accessTokenExpiryTime,
  refreshTokenExpiryTime
) => {
  if (isRefreshTokenEnabled) {
    setAccessTokenExpiryTime(accessTokenExpiryTime);
    setRefreshTokenExpiryTime(refreshTokenExpiryTime);

    storePersistedExpiryTokenTime({
      token_expired: accessTokenExpiryTime,
      refresh_expired: refreshTokenExpiryTime
    });
  }
};

// </getters-and-setters>

// <token-management>

/**
 * create axios instance menggunakan fungsi ini.
 * fungsi ini otomatis masukin token ke headers dan otomatis refresh token kalau expired sehingga tak perlu handle sendiri manual
 * parameter `setHeaders` wajib ada. merupakan fungsi yang menerima object `headers` dan jwt `token`. di fungsi ini siakan set sendiri tokennya mau dipasang di header apa.
 */

export const isRefreshTokenEnabled =
  process.env.REACT_APP_IS_REFRESH_TOKEN_ENABLED === 'true';

const TOKEN_STATIC_CUSTOMER = process.env.REACT_APP_STATIC_TOKEN_CRM;

export const isLoggedIn =
  loadPersistedTokens() || loadPersistedExpiryTokenTime();
export const createAxios = (opts = {}) => {
  const instance = axios.create({
    ...opts,
    headers: {
      'Content-Type': 'application/json',
      'X-Client-Id': 'efarm',
      'X-Client-Version': packageJson.version
    },
    withCredentials: isRefreshTokenEnabled
  });

  const onBeforeRequest = async (config) => {
    if (isRefreshTokenEnabled) await refreshIfExpired();
    const headers = config?.headers || {};
    if (typeof opts.setHeaders !== 'undefined')
      opts?.setHeaders(headers, getAccessToken());
    config.headers = headers;
    return config;
  };

  const onRequestError = (err) => {
    return Promise.reject(err);
  };

  instance.interceptors.request.use(onBeforeRequest, onRequestError);
  return instance;
};

export const createAxiosCustomer = (opts = {}) => {
  const instance = axios.create({
    ...opts,
    headers: {
      'X-App-token': TOKEN_STATIC_CUSTOMER,
      'X-Client-Id': 'toko_budidaya'
    }
  });

  const onBeforeRequest = async (config) => {
    if (isRefreshTokenEnabled) await refreshIfExpired();
    const headers = config?.headers || {};

    if (typeof opts.setHeaders !== 'undefined')
      opts?.setHeaders(headers, getAccessToken());
    config.headers = headers;
    return config;
  };

  const onRequestError = (err) => {
    return Promise.reject(err);
  };

  instance.interceptors.request.use(onBeforeRequest, onRequestError);
  return instance;
};

const fetchApiRequest = async (endpoint) => {
  const res = await axios.post(`${authsUrl}${endpoint}`, null, {
    withCredentials: true
  });
  return res?.data?.data;
};

/**
 * refresh token expired ke auth-svc
 */
const maxCacheAge = 10000;
const refreshIfExpired = mem(
  async (overheadSecond = DEFAULT_OVERHEAD_SECOND) => {
    // pakai memo supaya request refresh token di cache, max 10 detik, biar gaada call berulang
    if (refreshToken === 'disabled') return;

    const accessTokenExpiryTime = loadPersistedExpiryTokenTime();
    const isAccessTokenExpired = checkExpired(
      overheadSecond,
      accessTokenExpiryTime?.token_expired
    );
    if (!isAccessTokenExpired) return;

    try {
      const res = await fetchApiRequest('/users/refresh');
      setTokenExpiryTime(res.token_expired, res.refresh_expired);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          error.message = new InvalidTokenError(
            'Sesi Anda sudah habis. Silakan login ulang.'
          );
          doLogout();
        } else {
          error.message = new AuthServiceError(
            `HTTP ${error?.response?.status}`
          );
        }
      }
      throw error;
    }
  },
  {
    maxAge: maxCacheAge
  }
);

export const doLogout = async () => {
  try {
    await fetchApiRequest('/users/signout');
  } catch (error) {
    error.message = new AuthServiceError(`HTTP ${error?.response?.status}`);
  } finally {
    const coachmarkDashboardFlag = getCoachmarkDashboardFlag();
    if (coachmarkDashboardFlag) storeCoachmarkDashboardFlag();
    removePersistedExpiryTokenTime();
    removePersistedTokens();
    removePersistedName();
    removeToolsbudToken();
    deleteLeadsState();
    deleteLocationState();
    deleteDummyState();
    deleteAcquisitionState();
    deletePersistedHistory();
    deletePersistedHistoryDetail();
    deleteStateBenefitShrimp();
    window.location.replace('/');
  }
};

// </lib-wrappers>
