export const interstitialType = [
  'interstitial',
  'interstitial-fullscreen',
  'interstitial-image',
  'interstitial-image-fullscreen',
  'interstitial-image-only'
];
export const bannerType = ['banner', 'banner-image'];

export const validTemplateType = ['box', ...interstitialType, ...bannerType];

export const regexPattern = {
  imgSrcPattern: /src=['"](.*?)['"]/i,
  bannerStylePattern: /\.CT_Banner(?!\w).[^{]*{([^}]*)}/i,
  bannerBtnPattern:
    /<a[^>]*class=['"]jsCT_CTA CT_BannerCTA['"][^>]*>(.*?)<\/a>/i,
  interstitialStylePattern: /\.CT_Interstitial(?!\w).[^{]*{([^}]*)}/i,
  interstitialBtnPattern:
    /<a[^>]*class=['"]jsCT_CTA CT_InterstitialCTA['"][^>]*>(.*?)<\/a>/i
};

export const defaultPopupStyle = {
  backgroundColor: '',
  textColor: '',
  buttonColor: '',
  buttonTextColor: '',
  buttonText: 'Kunjungi'
};
