const userAgent = navigator.userAgent;
const platform =
  navigator?.userAgentData?.platform || navigator?.platform || 'unknown';

export const isiOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(platform) ||
    // iPad on iOS 13 detection
    (userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isAndroid = () => {
  return /Android/i.test(userAgent);
};

export const isUsingBrowser = () => {
  return typeof window !== 'undefined';
};
