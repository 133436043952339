import Firebase from 'firebase/compat/app';
import { ConfigEnv } from './env';
import { getMessaging } from 'firebase/messaging/sw';
import { getToken } from 'firebase/messaging';
import { postDevices } from 'api/backend';
import { trackNotificationReceived } from './analytics';
import { isPushNotificationSupported } from './notification';

const firebaseConfig = ConfigEnv.firebase;
export const firebase = Firebase.initializeApp(firebaseConfig);

let messaging;
if (isPushNotificationSupported()) {
  messaging = getMessaging(firebase);
}

export const getFirebaseToken = async (lead_id) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
    });
    if (currentToken) {
      await postDevices(lead_id, currentToken);
      return true;
    } else {
      console.log(`firebaseTokenError: ${currentToken}`);
      return false;
    }
  } catch (err) {
    console.log(`firebaseTokenError: ${err}`);
    return false;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      trackNotificationReceived();
      resolve(payload);
    });
  });
