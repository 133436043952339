import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 5 * 60 * 1000,
      keepPreviousData: true
    }
  }
});

export default queryClient;
