import styled from 'styled-components';

interface TypographyProps {
  block: boolean;
  color:
    | 'grey'
    | 'white'
    | 'green'
    | 'orange'
    | 'yellow'
    | 'red'
    | 'black'
    | 'black-100'
    | 'black-300'
    | 'black-700'
    | 'primary-600'
    | 'primary-700';
  height: '400' | '600' | '800';
  weight: number;
  variant: '100' | '200' | '300' | '400' | '500' | 'caption' | 'small';
}

const Typography = styled.div<Partial<TypographyProps>>`
  margin: 0;
  ${(props) => props.block && `display:${!props.block && 'inline-block'};`}
  font-size: ${(props) => props.variant === 'caption' && '8px'};
  font-size: ${(props) => props.variant === 'small' && '10px'};
  font-size: ${(props) => props.variant === '100' && '12px'};
  font-size: ${(props) => props.variant === '200' && '14px'};
  font-size: ${(props) => props.variant === '300' && '16px'};
  font-size: ${(props) => props.variant === '400' && '18px'};
  font-size: ${(props) => props.variant === '500' && '20px'};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color === 'grey' && '#A5A5A5'};
  color: ${(props) => props.color === 'white' && '#FFFFFF'};
  color: ${(props) => props.color === 'green' && '#038767'};
  color: ${(props) => props.color === 'orange' && '#C05717'};
  color: ${(props) => props.color === 'yellow' && '#f49342'};
  color: ${(props) => props.color === 'red' && '#D44140'};
  color: ${(props) => props.color === 'black' && '#1D1D1D'};
  color: ${(props) => props.color === 'black-100' && '#D2D2D2'};
  color: ${(props) => props.color === 'black-300' && '#777777'};
  color: ${(props) => props.color === 'black-700' && '#26282B'};
  color: ${(props) => props.color === 'primary-600' && '#026C52'};
  color: ${(props) => props.color === 'primary-700' && '#02513E'};
  line-height: ${(props) => props.height === '400' && '18px'};
  line-height: ${(props) => props.height === '600' && '22px'};
  line-height: ${(props) => props.height === '800' && '26px'};
  letter-spacing: 0.5px;
`;

export default Typography;
