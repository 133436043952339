import { getItem, setItem } from './skeleton';

const TOKENS_STORAGE_KEY = 'sfdu_token';
const EXPIRY_TIME_TOKENS_STORAGE_KEY = 'expiry_time_sfdu_token';
const NAME_STORAGE_KEY = 'farmer_name';

export const loadPersistedExpiryTokenTime = () => {
  const expiryTimes =
    getItem(EXPIRY_TIME_TOKENS_STORAGE_KEY) !== undefined &&
    getItem(EXPIRY_TIME_TOKENS_STORAGE_KEY) !== ''
      ? JSON.parse(getItem(EXPIRY_TIME_TOKENS_STORAGE_KEY))
      : null;
  return expiryTimes;
};

export const storePersistedExpiryTokenTime = (expiryTimes) => {
  const expiryTimesJson = JSON.stringify(expiryTimes);
  setItem(EXPIRY_TIME_TOKENS_STORAGE_KEY, expiryTimesJson);
};

export const removePersistedExpiryTokenTime = () => {
  localStorage.removeItem(EXPIRY_TIME_TOKENS_STORAGE_KEY);
};

export const loadPersistedTokens = () => {
  try {
    const tokensJson = localStorage.getItem(TOKENS_STORAGE_KEY);
    const tokens = JSON.parse(tokensJson);
    return tokens;
  } catch (e) {
    console.error(e);
  }
};

export const storePersistedTokens = (tokens) => {
  const tokensJson = JSON.stringify(tokens);
  localStorage.setItem(TOKENS_STORAGE_KEY, tokensJson);
};

export const removePersistedTokens = () => {
  localStorage.removeItem(TOKENS_STORAGE_KEY);
};

export const loadPersistedName = () => {
  const name = localStorage.getItem(NAME_STORAGE_KEY);
  return name;
};

export const storePersistedName = (name) => {
  localStorage.setItem(NAME_STORAGE_KEY, name);
};

export const removePersistedName = () => {
  localStorage.removeItem(NAME_STORAGE_KEY);
};

export const storeDeviceToken = (device_id) => {
  localStorage.setItem('efarm@device_id', device_id);
};

export const getDeviceToken = () => {
  return localStorage.getItem('efarm@device_id');
};
