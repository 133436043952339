import {
  fetchAndActivate,
  getAll,
  getRemoteConfig
} from 'firebase/remote-config';
import { firebase } from '../libs/firebase';

export const defaultValuesFlagsContext = {
  isWaterParameterEnabled: true,
  isRecommendationEnabled: true,
  isCycleIdentityEnabled: true,
  isSREnabled: true,
  isTotalFeedEnabled: true,
  isFCREnabled: true,
  isGrowthEnabled: true,
  isFeedLogsEnabled: true,
  isDiseaseEnabled: true,
  isHarvestEnabled: true,
  isSaprodiEnabled: true,
  isAmoniaAndNitriteEnabled: true,
  isLandingPageEnabled: true,
  isAcquisitionFormEnabled: true,
  isUsingNewDashboardVersion2: true,
  isVideoCanLike: true,
  isVideoCanComment: true,
  isSellShrimpEnabled: true,
  isCultivationCalculatorEnabled: true,
  isMarketplaceEnabled: true,
  isSurveyCalculatorEnabled: true,
  isMyPondsEnabled: false,
  isSurvicateCanClose: false,
  isDownloadPromptEnabled: false,
  isHarvestShrimpEnabledV2: false,
  isCultivationEventEnabled: false,
  isCultivationEventTutorialVideoEnabled: false,
  isCultivationHomePageEnabled: false,
  isProductRecommendationEnabled: false,
  isRelatedEventEnabled: false,
  isShipmentCostAndAddressEnabled: false,
  isCultivationRecordsEnabled: false,
  isOtpByWAEnabled: false,
  isListPromoEnabled: false,
  isNewConsultationEnabled: false,
  isUsingNewHistoryOrder: false,
  isMaintenanceEnabled: false,
  isReportPerCycleEnabled: false,
  isConsultationRegisterPremiumEnabled: false,
  isNotificationEnabled: true,
  isNonVerifiedFarmerInterceptorEnabled: false,
  isMarketplaceUsingGuestMode: false,
  isReportDetailsEnabled: false,
  isProductBestSellingEFarmEnabled: false,
  is3plInfoEnable: false,
  isABTestingProductEnabled: false,
  isJoinMentoringMemberPageEnabled: false,
  isRefreshTokenEnabled:
    process.env.REACT_APP_IS_REFRESH_TOKEN_ENABLED === 'true',
  isProductBundlingEnabled: false,
  isInternalModeEnabled: false,
  tokbudCsNumber: '-',
  expiryDurationPayment: '1440',
  isPaymentGatewayEnabled: false,
  isConfirmOrderCompleteEnabled: false,
  isComplaintFormEnabled: false,
  isFeedbackCustomerEnabled: true,
  //hapus kalau sudah aman di prod
  isGetWithLeadIdFarmsCultivation: false,
  isComplaintRequestTokenEnabled: false
};

export const remoteConfig = () => {
  try {
    return getRemoteConfig(firebase);
  } catch (err) {
    if (err.response) {
      err.message = `Error get remote config ${err.response.status}`;
    }
    throw err;
  }
};

export const fetchFlags = async () => {
  let newFlags = {};
  try {
    await fetchAndActivate(remoteConfig());
    const remoteFlags = getAll(remoteConfig());

    const keyWithObjectValues = [
      'categoryDailyReports',
      'isReportDetailsCategoryEnabled'
    ];
    const keyWithStringValues = ['tokbudCsNumber', 'expiryDurationPayment'];

    for (const [key, config] of Object.entries(remoteFlags)) {
      if (keyWithObjectValues.includes(key)) {
        newFlags[key] = config._value ? JSON.parse(config._value) : {};
      } else if (keyWithStringValues.includes(key)) {
        newFlags[key] = config._value;
      } else {
        newFlags[key] = config.asString() === 'true';
      }
    }
    return newFlags;
  } catch (error) {
    if (error.response) {
      error.message = `Error fetching flag HTTP ${error.response.status}`;
    }
    throw error;
  }
};
