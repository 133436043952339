import SecureLS from 'secure-ls';

const secureStorage = new SecureLS({ encodingType: 'aes' });
const isProduction = process.env.NODE_ENV === 'production';

export const setItem = (key: string, value: string): void => {
  isProduction
    ? secureStorage.set(key, value)
    : localStorage.setItem(key, value);
};

export const getItem = <Type>(key: string): Type | string | null => {
  if (!isProduction) {
    return localStorage.getItem(key);
  }

  try {
    return secureStorage.get(key);
  } catch (e) {
    const value = localStorage.getItem(key);
    secureStorage.set(key, value);
    return secureStorage.get(key);
  }
};

export const removeItem = (key: string): void => {
  isProduction ? secureStorage.remove(key) : localStorage.removeItem(key);
};
