const SHRIMP_HARVEST_FORM_STORAGE_KEY = 'shrimp-harvest-state';

export const storeStateBenefitShrimp = (state) => {
  localStorage.setItem('efarm@modal-shrimp-harvest-benefit', state);
};

export const getStateBenefitShrimp = () => {
  let state = localStorage.getItem('efarm@modal-shrimp-harvest-benefit');
  if (state) {
    return false;
  }
  return true;
};

export const deleteStateBenefitShrimp = () => {
  localStorage.removeItem('efarm@modal-shrimp-harvest-benefit');
};

export const storeFormShrimpState = (state) => {
  localStorage.setItem(SHRIMP_HARVEST_FORM_STORAGE_KEY, JSON.stringify(state));
};

export const getFormShrimpState = () => {
  const state =
    JSON.parse(localStorage.getItem(SHRIMP_HARVEST_FORM_STORAGE_KEY)) || '';
  return state;
};

export const deleteFormShrimpState = () => {
  localStorage.removeItem(SHRIMP_HARVEST_FORM_STORAGE_KEY);
};

export const persistHistoryState = (data) => {
  let payload = JSON.stringify(data);
  localStorage.setItem('efarm@store-persisted-history', payload);
};

export const getPersistedHistory = () => {
  const state =
    JSON.parse(localStorage.getItem('efarm@store-persisted-history')) || [];
  return state;
};

export const deletePersistedHistory = () => {
  localStorage.removeItem('efarm@store-persisted-history');
};

export const persistHistoryDetailState = (data) => {
  let payload = JSON.stringify(data);
  localStorage.setItem('efarm@store-persisted-history-detail', payload);
};

export const deletePersistedHistoryDetail = () => {
  localStorage.removeItem('efarm@store-persisted-history-detail');
};
