import TagManager from 'react-gtm-module';
import { ConfigEnv } from './env';
import { isTrustedWebActivity } from 'libs/twaChecker';
import clevertap from 'clevertap-web-sdk';
import { phoneInvertNormalize } from './helpers';

export const initializeGtm = () => {
  const tagManagerArgs = {
    gtmId: ConfigEnv.analytics.gtmId
  };
  return TagManager.initialize(tagManagerArgs);
};

/* eslint-disable */
export const initializeFbPixel = () => {
  if (typeof fbq === 'undefined') {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );
    fbq('init', ConfigEnv.analytics.fbPixelId);
    fbq('track', 'PageView');
  }
};

export const initializeAppsFlyer = () => {
  !(function (t, e, n, s, a, c, i, o, p) {
    (t.AppsFlyerSdkObject = a),
      (t.AF =
        t.AF ||
        function () {
          (t.AF.q = t.AF.q || []).push(
            [Date.now()].concat(Array.prototype.slice.call(arguments))
          );
        }),
      (t.AF.id = t.AF.id || i),
      (t.AF.plugins = {}),
      (o = e.createElement(n)),
      (p = e.getElementsByTagName(n)[0]),
      (o.async = 1),
      (o.src =
        'https://websdk.appsflyer.com?' +
        (c.length > 0 ? 'st=' + c.split(',').sort().join(',') + '&' : '') +
        (i.length > 0 ? 'af_id=' + i : '')),
      p.parentNode.insertBefore(o, p);
  })(window, document, 'script', 0, 'AF', 'pba', {
    pba: { webAppId: ConfigEnv.analytics.appsFlyerId }
  });
};

export const initializeCleverTap = () => {
  // configure projectId and region.
  clevertap.init(ConfigEnv.clevertap.projectId, ConfigEnv.clevertap.region);
  // share IP data
  clevertap.privacy.push({ useIP: true });
  // configure apps project is SPA or not.
  clevertap.spa = true;

  if (process.env.REACT_APP_ENV !== 'production') {
    // https://developer.clevertap.com/docs/web-quickstart-guide#debugging
    clevertap.setLogLevel(3);
  }
};
/* eslint-enable */

let checkTraffic = isTrustedWebActivity();

let traffic_route = {
  traffic_route: checkTraffic ? 'trusted-web-activity' : 'browser-app'
};

export const trackEvent = (eventName, eventProperties) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      eventProps: {
        ...eventProperties,
        ...traffic_route
      }
    });
    window.fbq('trackCustom', eventName, eventProperties);
    window.AF('pba', 'event', {
      eventType: 'EVENT',
      eventValue: eventProperties,
      eventName: `af_${eventName}`
    });
  }
};

export const trackAFUserId = (userId) => {
  window.AF('pba', 'setCustomerUserId', userId);
};

export const trackerLandingPage = {
  trackButtonMasuk: () => {
    trackEvent('btn_login_clicked');
  },
  trackTopButtonDaftar: () => {
    trackEvent('btn_top_register_clicked');
  },
  trackFeature: (feature) => {
    trackEvent('landing_page_features', {
      landing_page_features: feature
    });
  },
  trackBottomButtonDaftar: () => {
    trackEvent('btn_bottom_register_clicked');
  },
  trackTestimonialButtonDaftar: () => {
    trackEvent('btn_testimonial_register_clicked');
  },
  trackConsultationClicked: () => {
    trackEvent('btn_consultation_clicked');
  },
  trackTestimoni: () => {
    trackEvent('slide_testimoni');
  },
  trackConsultationCardClicked: () => {
    trackEvent('card_consultation_pressed');
  },
  trackDeeplink: (campaign) => {
    trackEvent('deeplink_triggered', {
      utm_campaign: campaign
    });
  }
};

export const trackerDashboard = {
  trackConsultationChatStarted: () => {
    trackEvent('tawkto_consultation_cultivation_started');
  },
  trackBannerClick: (bannerId) => {
    trackEvent('chosen_banner', {
      banner_id: bannerId
    });
  },
  trackCloseSheetNotif: () => {
    trackEvent('btn_close_sheet_notif');
  },
  trackTabChanges: (pathName) => {
    let tabName;

    if (pathName === '/') {
      tabName = 'Beranda';
    } else if (pathName === '/cultivation') {
      tabName = 'Budidaya';
    } else if (pathName === '/settings') {
      tabName = 'Akun';
    }

    trackEvent('tab_change', {
      tab_name: tabName
    });
  },
  trackLogout: () => {
    trackEvent('logout');
  },
  trackHelp: (page = '') => {
    trackEvent('btn_help_clicked', {
      page: page
    });
  },
  trackMarketplaceCardClicked: () => {
    trackEvent('card_marketplace_pressed');
  },
  trackWAConsultationClicked: (consultant_phone) => {
    trackEvent('button_consultation_direct_whatsapp', {
      consultant_phone: consultant_phone
    });
  }
};

export const trackerLaporanHarian = {
  trackBack: () => {
    trackEvent('back_from_daily_report');
  }
};

export const trackerLaporanSiklus = {
  trackBack: () => {
    trackEvent('back_from_cyle');
  },
  trackGrowth: (value) => {
    trackEvent('btn_growth', {
      growth: value
    });
  },
  trackFeedig: (value) => {
    trackEvent('btn_feedig', {
      feedig: value
    });
  }
};

export const trackerQuestionnaire = {
  trackIsFarmers: () => {
    trackEvent('click_is_farmers');
  },
  trackIsNotFarmers: () => {
    trackEvent('click_is_not_farmers');
  },
  trackInterest: () => {
    trackEvent('click_interest');
  },
  trackReferral: () => {
    trackEvent('click_referral');
  }
};

export const trackerFeatureStopperSheets = {
  trackIsFarmerToggle: () => {
    trackEvent('close_is_farmer_sheet');
  },
  trackIsNotFarmerToggle: () => {
    trackEvent('close_is_not_farmer_sheet');
  }
};

export const trackBackAcquisitionForm = () => {
  trackEvent('back_from_acquisition_form');
};

export const trackAcquisitionState = () => {
  trackEvent('acquisition_state_saved');
};

export const trackIsiDataDiri = () => {
  trackEvent('click_isi_data_diri');
};

export const trackButtonUpdateData = () => {
  trackEvent('click_update_data');
};

export const trackPlanClicked = (planName) => {
  trackEvent('wa_plan_clicked', {
    plan_name: planName
  });
};

export const trackUtm = (utmParams) => {
  if (utmParams !== null) trackEvent('utm_tracked', utmParams);
};

export const trackSelectBlock = () => {
  trackEvent('select_block');
};

export const trackSelectPond = () => {
  trackEvent('select_pond');
};

export const trackDetailKolam = () => {
  trackEvent('detail_pond');
};

export const trackLaporanSiklus = () => {
  trackEvent('click_laporan_per_siklus');
};

export const trackPengaturan = () => {
  trackEvent('click_settings');
};

export const trackExpandRecommendation = () => {
  trackEvent('expand_recommendation');
};

export const trackNotificationReceived = () => {
  trackEvent('notification_received');
};

export const trackNotificationClicked = () => {
  trackEvent('notification_clicked');
};

export const trackNotifHomepage = () => {
  trackEvent('notif_homepage');
};

export const trackNotifPengaturan = () => {
  trackEvent('notif_pengaturan');
};

// Event Tracker Panen Udang
export const trackFabShrimpForm = () => {
  trackEvent('fab_shrimp_form');
};

export const trackNextShrimpForm = () => {
  trackEvent('btn_next_step_shrimp_form_clicked');
};

export const trackGoToHarvestHistory = () => {
  trackEvent('btn_to_harvest_history_clicked');
};

export const trackChangeNumberShrimpForm = () => {
  trackEvent('input_change_number_clicked');
};

export const trackChangeLocationShrimpForm = () => {
  trackEvent('btn_change_location_clicked');
};

export const trackSubmitShrimpFormData = (payload) => {
  trackEvent('shrimp_form_submitted', {
    shrimp_form_data: payload
  });
};

export const trackSlideBenefitPanenUdang = () => {
  trackEvent('slide_benefit_panen_udang');
};

export const trackTertarikPanenUdang = () => {
  trackEvent('btn_tertarik_panen_udang');
};

export const trackCloseBenefitPanenUdang = () => {
  trackEvent('btn_close_popup_benefit_panen_udang');
};

export const trackBannerPanenUdang = () => {
  trackEvent('slide_banner_panen_udang');
};

export const trackShrimpHarvest = (category) => {
  trackEvent('chosen_panen_udang_category', {
    chosen_panen_udang_category: category
  });
};

export const trackChooseHistory = (transaction_id) => {
  trackEvent('chosen_history_panen_udang', {
    transaction_id: transaction_id
  });
};

export const closeHistory = (transaction_id) => {
  trackEvent('close_history_panen_udang', {
    transaction_id: transaction_id
  });
};

export const trackSharePanenUdang = () => {
  trackEvent('btn_share_panen_udang');
};

export const trackSharePanenUdangSocialMedia = (social_media) => {
  trackEvent(`btn_share_panen_udang_${social_media}`);
};

export const trackShrimpHarvestCardClicked = () => {
  trackEvent('card_sell_shrimp_pressed');
};

export const trackPanenUdangNonVerifiedFarmer = () => {
  trackEvent('open_panen_udang_non_verified_sheet');
};

export const trackPanenUdangNonVerifiedFarmerClicked = (feature_name) => {
  trackEvent('btn_panen_udang_feature_clicked', { feature_name });
};

export const trackPanenUdangNonVerifiedUpdateProfile = () => {
  trackEvent('btn_panen_udang_update_profile');
};

// End Event Tracker Panen Udang

// Tracker Marketplace / Toko Budidaya
export const trackBackFromTokoBudidaya = () => {
  trackEvent('back_from_toko_budidaya');
};

export const trackSearchInputHomepage = (keyword) => {
  trackEvent('search_input_homepage', {
    keyword
  });
};

export const trackChosenProductPakan = (product_id) => {
  trackEvent('chosen_product_pakan', {
    product_id
  });
};

export const trackChosenProductNonPakan = (product_id) => {
  trackEvent('chosen_product_nonpakan', {
    product_id
  });
};

export const trackChosenProductCategory = (category_name) => {
  trackEvent('chosen_product_category', {
    category_name
  });
};

export const trackBackFromPCP = () => {
  trackEvent('back_from_pcp');
};

export const trackSearchInputPCP = (keyword) => {
  trackEvent('search_input_pcp', {
    keyword
  });
};

export const trackChosenProduct = (product_id) => {
  trackEvent('chosen_product', {
    product_id
  });
};

export const trackOrderPCP = (product_id) => {
  trackEvent('order_pcp', {
    product_id
  });
};

export const trackOrderPDP = (product_id) => {
  trackEvent('order_pdp', {
    product_id
  });
};

export const trackBackFromOrderDetail = () => {
  trackEvent('back_from_order_detail');
};

export const trackChosenPayment = (duration_name) => {
  trackEvent('chosen_payment', { duration_name });
};

export const trackFinishedPayment = (duration_name) => {
  trackEvent('finished_payment', { duration_name });
};

export const trackDetailProduct = (product_id) => {
  trackEvent('see_detail_product', {
    product_id
  });
};

export const trackCartPage = () => {
  trackEvent('click_cart');
};

export const trackClickPayment = () => {
  trackEvent('click_payment_button');
};

export const trackPaymentPage = () => {
  trackEvent('see_payment_page');
};

export const trackProductRecommendation = (
  product_recommendation_id,
  entry_point
) => {
  trackEvent('chosen_product_recommendation', {
    product_recommendation_id,
    entry_point
  });
};

export const trackDirectToTokbud = () => {
  trackEvent('direct_to_tokbud_recommendation_efarm');
};

export const trackProductPersonalize = (product_id, model_name) => {
  trackEvent('chosen_product_personalize', {
    product_id,
    model_name
  });
};

export const trackProductGeneral = (product_id, model_name) => {
  trackEvent('chosen_product_general', {
    product_id,
    model_name
  });
};
// End Tracker Marketplace / Toko Budidaya

/**
 * clevertapEventPush
 * Fungsi untuk push event ke dashboard clevertap.
 * eventName dan eventProperties di set bebas di components.
 *
 * @param {string} eventName | nama event untuk dijadikan segment.
 * @param {object} eventProperties | object event yang akan di jadikan data tracking.
 */
export const clevertapEventPush = ({ eventName, eventProperties }) => {
  clevertap.event.push(eventName, eventProperties);
};

/**
 * clevertapEventUserLogin
 * Fungsi untuk push data user ketika sudah login.
 * Event : Page Load
 *
 * @param {*}
 * @param {string} {identity} | UUID pengguna
 * @param {string} {name} | nama pengguna
 * @param {string} {phone} | telepon pengguna
 * @param {string} {email} | email pengguna
 * @param {boolean} {verified} | status pengguna
 * @param {string} {position} | posisi pengguna
 * @param {string} {province} | provinsi pengguna
 * @param {string} {city} | kota pengguna
 * @param {string} {district} | kelurahan pengguna
 * @param {string} {subDistrict} | desa pengguna
 * @param {string} {cultivationType} | type cultivation pengguna
 */
export const clevertapEventUserLogin = ({
  identity,
  name,
  phone,
  email,
  verified,
  position,
  province,
  city,
  district,
  subDistrict,
  cultivationType
}) => {
  clevertap.onUserLogin.push({
    Site: {
      Identity: identity,
      Email: email,
      Name: name,
      Phone: phoneInvertNormalize(phone),
      Verified: verified,
      Position: position,
      Province: province,
      City: city,
      District: district,
      'Sub-District': subDistrict,
      'Cultivation Type': cultivationType
    }
  });
};

/**
 * eventEditProfile
 * Fungsi untuk push event update profile
 */
export const clevertapEventUpdateProfile = ({
  name,
  position,
  province,
  city,
  district,
  subDistrict
}) => {
  clevertap.profile.push({
    Site: {
      Name: name,
      Position: position,
      Province: province,
      City: city,
      District: district,
      'Sub-District': subDistrict
    }
  });
};

/**
 * clevertapGrantPermission
 * Fungsi untuk memunculkan clevertap popup grant permission.
 * Contoh payload
 *
 * {
 *   titleText: 'Would you like to receive Push Notifications?',
 *   bodyText:
 *     'We promise to only send you relevant content and give you updates on your transactions',
 *   okButtonText: 'Sign me up!',
 *   rejectButtonText: 'No thanks',
 *   okButtonColor: '#f28046'
 * }
 */
export const clevertapGrantPermission = (webPushPayload) => {
  // {
  //   titleText: 'Would you like to receive Push Notifications?',
  //   bodyText:
  //     'We promise to only send you relevant content and give you updates on your transactions',
  //   okButtonText: 'Sign me up!',
  //   rejectButtonText: 'No thanks',
  //   okButtonColor: '#f28046'
  // }
  clevertap.notifications.push({
    ...webPushPayload,
    serviceWorkerPath: '/service-worker.js'
  });
};
