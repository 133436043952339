import { init } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { isLocalhost } from './helpers';

// Sentry init
export default () => {
  const environment = process.env.REACT_APP_ENV;
  const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

  if (!isLocalhost) {
    init({
      dsn: sentryDsn,
      integrations: [new BrowserTracing()],
      environment,
      tracesSampleRate: 1.0
    });
  }
};
