import { FC, memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import CircleCheckIcon from 'assets/icons/icon-check-circle.svg';
import CircleCrossIcon from 'assets/icons/icon-cross-circle-white.svg';
import Typography from 'components/reusable/Typography';

interface IPopupAlertProps {
  isOpen: boolean | false;
  onClose: () => void;
  message?: string | null;
  variant: string;
  showIcon?: boolean;
}

interface IPopupAlertStyleProps {
  isOpen: boolean | false;
  variant: string;
}

const Alert = styled.div<IPopupAlertStyleProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  z-index: 13;
  position: fixed;
  height: 56px;
  left: 16px;
  top: 40px;
  background: ${({ variant }) =>
    variant === 'danger' ? '#D44140' : '#038767'};
  width: 92%;
  border-radius: 8px;
  transition: visibility 0s, opacity 0.5s linear;
`;

const Wrapper = styled.div`
  padding: 24px 0 24px 24px;
`;

const PopupAlert: FC<IPopupAlertProps> = ({
  isOpen,
  onClose,
  message,
  variant,
  showIcon = true
}) => {
  const handleClosePopupAlert = useCallback(() => {
    setTimeout(() => {
      onClose();
    }, 2000);
  }, [onClose]);

  useEffect(() => {
    if (isOpen) handleClosePopupAlert();
  }, [isOpen, handleClosePopupAlert]);

  return (
    <Alert isOpen={isOpen} variant={variant}>
      {showIcon && (
        <Wrapper>
          <img
            src={variant === 'danger' ? CircleCrossIcon : CircleCheckIcon}
            alt="icon-circle"
            loading="lazy"
          />
        </Wrapper>
      )}
      <Typography color="white" className="ml-4">
        {message}
      </Typography>
    </Alert>
  );
};

export default memo(PopupAlert);
