export const isPushNotificationSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

export const isPushNotificationEnabled = () => {
  if (isPushNotificationSupported()) {
    let state = Notification.permission;
    switch (state) {
      case 'granted':
        return 'granted';
      case 'denied':
        return 'denied';
      default:
        return 'default';
    }
  }
};
