import { lazy } from 'react';
import { IClevertapPopupProps } from '../interfaces';

// Lazy render
const PopupBox = lazy(() => import('./PopupBox'));
const PopupBanner = lazy(() => import('./PopupBanner'));
const PopupInterstitial = lazy(() => import('./PopupInterstitial'));
const PopupInterstitialFullscreen = lazy(
  () => import('./PopupInterstitialFullscreen')
);
const PopupInterstitialImage = lazy(() => import('./PopupInterstitialImage'));
const PopupInterstitialImageFullscreen = lazy(
  () => import('./PopupInterstitialImageFullscreen')
);
const PopupInterstitialImageOnly = lazy(
  () => import('./PopupInterstitialImageOnly')
);
const PopupBannerImage = lazy(() => import('./PopupBannerImage'));

export default function ClevertapPopup(props: IClevertapPopupProps) {
  const {
    notificationObject: { templateType }
  } = props;

  return (
    <>
      {templateType === 'interstitial' && <PopupInterstitial {...props} />}
      {templateType === 'interstitial-fullscreen' && (
        <PopupInterstitialFullscreen {...props} />
      )}
      {templateType === 'interstitial-image' && (
        <PopupInterstitialImage {...props} />
      )}
      {templateType === 'interstitial-image-fullscreen' && (
        <PopupInterstitialImageFullscreen {...props} />
      )}
      {templateType === 'interstitial-image-only' && (
        <PopupInterstitialImageOnly {...props} />
      )}
      {templateType === 'box' && <PopupBox {...props} />}
      {templateType === 'banner' && <PopupBanner {...props} />}
      {templateType === 'banner-image' && <PopupBannerImage {...props} />}
    </>
  );
}
