export const EFI_TOOLSBUD_TOKEN = '_efiToolsbudToken';

export function setToolsbudToken(token: string) {
  sessionStorage.setItem(EFI_TOOLSBUD_TOKEN, token);
}

export function getToolsbudToken() {
  return sessionStorage.getItem(EFI_TOOLSBUD_TOKEN);
}

export function removeToolsbudToken() {
  return sessionStorage.removeItem(EFI_TOOLSBUD_TOKEN);
}
