import { lazy } from 'react';
import iconShop from 'assets/icons/new-dashboard/icon-shop.png';
import iconPlay from 'assets/icons/new-dashboard/icon-play.png';
import iconCalculator from 'assets/icons/new-dashboard/icon-calculator.png';
import iconShrimp from 'assets/icons/new-dashboard/icon-shrimp.png';
import iconCalendar from 'assets/icons/new-dashboard/icon-calendar.png';
import iconConsultation from 'assets/icons/new-dashboard/icon-consultation.png';

const LearnCultivationCard = lazy(() =>
  import('components/home/new-dashboard/LearnCultivationCard')
);
const EventCultivationCard = lazy(() =>
  import('components/home/new-dashboard/EventCultivationCard')
);
const SellShrimpCard = lazy(() =>
  import('components/home/new-dashboard/SellShrimpCard')
);

const ConsultationCard = lazy(() =>
  import('components/home/new-dashboard/ConsultationCard')
);

export const featureCard = [
  {
    id: 'sell-shrimp-card',
    title: 'Rencana Panen',
    className: 'px-2',
    path: '/shrimp-harvest',
    icon: (
      <img src={iconShrimp} width="32" alt="Rencana Panen" loading="lazy" />
    ),
    oldComponent: <SellShrimpCard />
  },
  {
    id: 'marketplace-card',
    title: 'Toko Budidaya',
    path: '/marketplace',
    className: 'pr-0',
    icon: <img src={iconShop} width="32" alt="Toko Budidaya" loading="lazy" />,
    oldComponent: null
  },
  {
    id: 'consultation-card',
    title: 'Konsultasi',
    path: '/consultation/wa',
    className: 'pl-0',
    icon: (
      <img
        src={iconConsultation}
        width="32"
        alt="Konsultasi Budidaya"
        loading="lazy"
      />
    ),
    oldComponent: <ConsultationCard />
  },
  {
    id: 'learn-cultivation-card',
    title: 'Belajar Budidaya',
    path: '/learn-cultivation/list',
    className: 'pr-0',
    icon: (
      <img src={iconPlay} width="32" alt="Belajar Budidaya" loading="lazy" />
    ),
    oldComponent: <LearnCultivationCard />
  },
  {
    id: 'cultivation-calculator-card',
    title: 'Kalkulator',
    icon: (
      <img src={iconCalculator} width="32" alt="Rencana Panen" loading="lazy" />
    ),
    path: '/cultivation-calculator',
    oldComponent: null
  },
  {
    id: 'event-cultivation-card',
    title: 'Acara Budidaya',
    path: '/event-cultivation/list',
    className: 'pr-0',
    icon: (
      <img src={iconCalendar} width="32" alt="Acara Budidaya" loading="lazy" />
    ),
    oldComponent: <EventCultivationCard />
  }
  // {
  //   id: "financial-records",
  //   title: "Pencatatan Keuangan",
  //   path: "/learn-cultivation/list",
  //   className: "pr-0",
  //   icon: <img src={iconMoney} width="32" alt="Pencatatan Keuangan" />,
  //   oldComponent: <LearnCultivationCard />
  // }
];

export const belajar_budidaya = 'belajar_budidaya';
export const konsultasi_budidaya = 'konsultasi_budidaya';
export const rencana_panen = 'rencana_panen';
export const kalkulator_budidaya = 'kalkulator_budidaya';
export const toko_budidaya = 'toko_budidaya';
export const dl_konsultasi_budidaya = 'dl_konsultasi_budidaya';
export const DEEP_LINK_CREATE_FARM = 'buat_tambak';
