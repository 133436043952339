import {
  bannerType,
  defaultPopupStyle,
  interstitialType,
  regexPattern
} from '../constant';
import {
  ICTPopupMessage,
  IGetImageSourceProps,
  IGetPopupStyleProps,
  IGetTemplateType,
  PopupTemplateType
} from '../interfaces';

export function getImageSource(props: IGetImageSourceProps) {
  const { html } = props;
  return html.match(regexPattern.imgSrcPattern)?.[1];
}

export function getPopupStyle(props: IGetPopupStyleProps) {
  const { html, templateType } = props;
  const {
    interstitialBtnPattern,
    interstitialStylePattern,
    bannerBtnPattern,
    bannerStylePattern
  } = regexPattern;

  const style = defaultPopupStyle;

  switch (templateType) {
    case 'interstitial':
      // get styles
      html
        .match(interstitialStylePattern)?.[0]
        .split(';')
        .filter((x) => x.includes('background-color:') || x.includes('color:'))
        .forEach((x) => {
          const [properties, value] = x.split(':');
          if (properties === 'background-color') {
            style.backgroundColor = value;
          }
          if (properties === 'color') {
            style.textColor = value;
          }
        });

      // get Button text
      style.buttonText = html.match(interstitialBtnPattern)?.[1] ?? 'Kunjungi';
      break;
    case 'banner':
      // get styles
      html
        .match(bannerStylePattern)?.[0]
        .split(';')
        .filter((x) => x.includes('background-color:') || x.includes('color:'))
        .forEach((x) => {
          const [properties, value] = x.split(':');
          if (properties === 'background-color') {
            style.backgroundColor = value;
          }
          if (properties === 'color') {
            style.textColor = value;
          }
        });

      // get Button text
      style.buttonText = html.match(bannerBtnPattern)?.[1] ?? 'Kunjungi';
      break;
    default:
  }
  return style;
}

export function getTemplateType(props: IGetTemplateType) {
  const { templateType, kv } = props;
  switch (templateType) {
    case 'box':
      return 'box';
    case 'banner':
      if (bannerType.includes((kv?.template as string)?.toLowerCase()))
        return kv?.template as PopupTemplateType;
      return 'banner';
    case 'interstitial':
      if (interstitialType.includes((kv?.template as string)?.toLowerCase()))
        return kv?.template as PopupTemplateType;
      return 'interstitial';
    default:
      return templateType;
  }
}

export function normalizeKV(props: ICTPopupMessage['msgContent']['kv']) {
  // Normalize url if the url is empty string
  if (props?.url === '') {
    props.url = 'doNothing';
  }
  return props;
}
