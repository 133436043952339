import { getItem, setItem, removeItem } from './skeleton';
const LEADS_STATE_STORAGE_KEY = 'leads-state';

export const getLeadsState = () => {
  const state =
    getItem(LEADS_STATE_STORAGE_KEY) !== undefined &&
    getItem(LEADS_STATE_STORAGE_KEY) !== ''
      ? JSON.parse(getItem(LEADS_STATE_STORAGE_KEY))
      : '';
  return state;
};

export const setLeadsState = (state) => {
  setItem(LEADS_STATE_STORAGE_KEY, JSON.stringify(state));
};

export const editLeadsState = (key, value) => {
  const state = getLeadsState();
  state[key] = value;
  setLeadsState(state);
};

export const deleteLeadsState = () => {
  removeItem(LEADS_STATE_STORAGE_KEY);
};

export const loadPersistedName = () => {
  const state = getLeadsState();
  return state === '' ? 'Hai, Pembudidaya' : state?.name;
};
