import { trackEvent, trackAFUserId } from 'libs/analytics';

export const trackUserLogin = (username, leadsId, leadsName) => {
  trackEvent('login', {
    username,
    lead_id: leadsId,
    lead_name: leadsName
  });
  trackAFUserId(leadsId);
};

export const trackSignUp = (phoneNumber, questionnaire) => {
  trackEvent('sign_up_incomplete', {
    lead_phone: phoneNumber,
    questionnaire
  });
  trackAFUserId(phoneNumber);
};

export const trackCompleteSignUp = (leadsId, leadsName) => {
  trackEvent('sign_up', {
    lead_id: leadsId,
    lead_name: leadsName
  });
  trackAFUserId(leadsId);
};

export const trackUpdateData = (leadsId, leadsName) => {
  trackEvent('update_data', {
    lead_id: leadsId,
    lead_name: leadsName
  });
  trackAFUserId(leadsId);
};

export const trackAlreadyLoggedIn = (leadsId, leadsName, phone) => {
  trackEvent('already_logged_in', {
    lead_id: leadsId,
    lead_name: leadsName,
    phone
  });
  trackAFUserId(leadsId);
};

export const trackOtpRequested = (phone, media) => {
  trackEvent('otp_requested', {
    phone: `62${phone}`,
    media
  });
};

export const trackOtpValid = (phone) => {
  trackEvent('otp_valid', {
    phone: `62${phone}`
  });
};

export const trackOtpResend = (phone, media) => {
  trackEvent('otp_sent', {
    phone: `62${phone}`,
    media
  });
};

export const trackUserLoginPhone = (phone) => {
  trackEvent('login_phone', {
    phone
  });
};

export const trackerSignUpJourney = {
  trackLoginWithUsername: () => {
    trackEvent('btn_login_with_username');
  },
  trackLoginWithPhone: () => {
    trackEvent('btn_login_with_phone');
  },
  trackUseReferral: () => {
    trackEvent('btn_use_referral');
  },
  trackAttemptOTP: (value) => {
    trackEvent('attempt_otp', {
      try: value
    });
  }
};
