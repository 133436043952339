import React from 'react';
import { Container } from 'reactstrap';
import logo from 'assets/logo-text.png';

const LoadingScreen = () => (
  <Container className="full-height-centered">
    <img
      src={logo}
      width="200"
      height="auto"
      className="logo mb-2"
      alt="eFishery Logo"
    />
  </Container>
);

export default LoadingScreen;
