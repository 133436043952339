const DASHBOARD_UVP_CONSULTATION_KEY = 'has-opened-uvp-consultation';
const DASHBOARD_COACHMARK_KEY = 'has-opened-dashboard-coachmark';

export const storeCoachmarkDashboardFlag = () => {
  localStorage.setItem(DASHBOARD_COACHMARK_KEY, true);
};

export const getCoachmarkDashboardFlag = () => {
  return localStorage.getItem(DASHBOARD_COACHMARK_KEY);
};

export const removeCoachmarkDashboardFlag = () => {
  localStorage.removeItem(DASHBOARD_COACHMARK_KEY);
};

export const storeDashboardUVPFlag = () => {
  localStorage.setItem(DASHBOARD_UVP_CONSULTATION_KEY, true);
};

export const getDashboardUVPFlag = () => {
  return localStorage.getItem(DASHBOARD_UVP_CONSULTATION_KEY);
};
